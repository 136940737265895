import { Table } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import { DatabaseRecord, State } from '../utils/interfaces';
import { getMovements } from '../utils/movements';
import LastUpdatedString from './LastUpdatedString';

function Movement(
    props: { database: DatabaseRecord[] } = {} as { database: DatabaseRecord[] }
) {
    return (
        <>
            <LastUpdatedString name={'Movement'} />
            <Table
                rowKey="key"
                key="movement"
                loading={!props.database}
                dataSource={props.database ? [props.database[0]] : []}
                columns={getMovements().map((column: any) => ({
                    ...column,
                    width: `${200 / getMovements().length}%`,
                    key: column.title,
                    title: (
                        <img
                            src={`assets/${column.title.replace(
                                '_CZK',
                                ''
                            )}.svg`}
                            alt={column.title.replace('_CZK', '')}
                        />
                    ),
                }))}
                pagination={{ hideOnSinglePage: true }}
                scroll={{ y: 80 }}
            />
        </>
    );
}

const mapStateToProps = (state: State): { database: DatabaseRecord[] } => ({
    database: state.database,
});

export default connect(mapStateToProps, null)(Movement);
