import {
    Balance,
    CoinMateBalance,
    CoinMateBalanceType,
    CoinMateTicker,
    CurrencyStructure,
    DatabaseRecord,
    ExchangeRatesApi,
    Settings,
    TradeRow,
} from './interfaces';
import { getPairsArray, getPairsAsNumbersArray, PairEnum, PairType } from './pairs';
import { get, post, postBitfinex } from './request';

const allCalls = getPairsArray().length - 1; // exchangeratesapi, balances,

const recount = (
    callIndex: number,
    balance: Balance,
    settings: Settings,
    onDone: (newRecord: DatabaseRecord) => void
) => {
    if (callIndex < allCalls) {
        return;
    }

    const marginCZK = Number(settings.MARGIN_CZK);
    const originCZK = Number(settings.ORIGIN_CZK);
    const originBTC = Number(settings.ORIGIN_BTC);
    const databaseRecord = {} as DatabaseRecord;

    databaseRecord.fullBalance = 0;

    getPairsAsNumbersArray().forEach((pair: number) => {
        balance[pair].result = Math.round(balance[pair].rate * balance[pair].balance);
        databaseRecord.fullBalance += balance[pair].result;
    });

    databaseRecord.fullBalance -= marginCZK;
    databaseRecord.hodl = Math.round(balance[PairEnum.BTC_CZK].rate * originBTC);
    databaseRecord.dif = databaseRecord.fullBalance - databaseRecord.hodl;
    databaseRecord.profit = databaseRecord.fullBalance - originCZK;
    databaseRecord.date = new Date().getTime();

    databaseRecord.data = balance;

    // resolving callback
    onDone(databaseRecord);
};

export const fetch = (settings: Settings, onDone: (newRecord: DatabaseRecord) => void) => {
    let callIndex = 0;
    const marginBTC = Number(settings.MARGIN_BTC);

    const balance = {
        [PairEnum.BTC_CZK]: {} as CurrencyStructure,
        [PairEnum.LTC_CZK]: {} as CurrencyStructure,
        [PairEnum.ETH_CZK]: {} as CurrencyStructure,
        [PairEnum.XRP_CZK]: {} as CurrencyStructure,
        [PairEnum.DASH_CZK]: {} as CurrencyStructure,
        [PairEnum.BCH_CZK]: {} as CurrencyStructure,
        [PairEnum.CZK_CZK]: { rate: 1 } as CurrencyStructure,
        [PairEnum.EUR_CZK]: {} as CurrencyStructure,
        [PairEnum.USD_CZK]: {
            balance: settings.BITFINEX_BALANCE,
            avb: 0,
        } as CurrencyStructure,
    } as Balance;

    // get balances
    post('https://coinmate.io/api/balances', settings, (data: CoinMateBalance) => {
        Object.keys(data).forEach((currency: string) => {
            const coinmateCurrency: CoinMateBalanceType = currency as CoinMateBalanceType;
            const pair: PairType = `${coinmateCurrency}_CZK` as PairType;
            const pairIndex: PairEnum = PairEnum[pair];
            if (balance[pairIndex]) {
                balance[pairIndex].balance = data[coinmateCurrency].balance;
                balance[pairIndex].avb = +data[coinmateCurrency].available.toFixed(3);
                if (pairIndex === PairEnum.BTC_CZK) {
                    balance[pairIndex].balance += marginBTC;
                }
            }
        });
        recount(callIndex++, balance, settings, onDone);
    });

    // bitfinex
    postBitfinex(settings, (data: any) => {
        balance[PairEnum.USD_CZK].balance = Math.round(data);
        recount(callIndex++, balance, settings, onDone);
    });

    // get current rates
    getPairsArray()
        .filter((pair: PairType) => pair !== 'EUR_CZK' && pair !== 'CZK_CZK' && pair !== 'USD_CZK')
        .forEach((pair: PairType) => {
            get(`https://coinmate.io/api/ticker?currencyPair=${pair}`, (data: CoinMateTicker) => {
                balance[PairEnum[pair]].rate = data.last;

                recount(callIndex++, balance, settings, onDone);
            });
        });

    // get EUR and USD rates
    get(
        'https://free.currconv.com/api/v7/convert?q=EUR_USD,EUR_CZK&compact=ultra&apiKey=139fcc73b5f248921f64',
        (data: ExchangeRatesApi) => {
            balance[PairEnum.EUR_CZK].rate = data.EUR_CZK;
            balance[PairEnum.USD_CZK].rate = data.EUR_CZK / data.EUR_USD;
            recount(callIndex++, balance, settings, onDone);
        }
    );
};

export const trade = (settings: Settings, onDone: (data: TradeRow[]) => void) => {
    post('https://coinmate.io/api/tradeHistory', settings, onDone);
};
