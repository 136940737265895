export const actionTypes = {
    UPDATE_AUTH_USER: 'UPDATE_AUTH_USER',
    UPDATE_SETTINGS: 'UPDATE_SETTINGS',
    UPDATE_DATABASE: 'UPDATE_DATABASE',
};

export const getActionFunction = (action: string, data: any) => ({
    type: action,
    data,
});
