import { Col, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import { DatabaseRecord, State } from '../utils/interfaces';

function LastUpdatedString(
    props: {
        name: string;
        lastUpdate?: string;
        database?: DatabaseRecord[];
    } = {
        name: '',
        lastUpdate: '',
        database: undefined,
    }
) {
    return (
        <Row justify="space-between">
            <Col flex="auto">{props.name}</Col>
            {props.lastUpdate ?? ''}
            {!props.lastUpdate &&
                props.database &&
                props.database[0] &&
                new Date(props.database![0].date).toLocaleString('cs-CZ')}
            {!props.lastUpdate && (!props.database || !props.database[0]) && 'loading...'}
        </Row>
    );
}

const mapStateToProps = (state: State): { database: DatabaseRecord[] } => ({
    database: state.database,
});

export default connect(mapStateToProps, null)(LastUpdatedString);
