export enum PairEnum {
    BTC_CZK = 0,
    LTC_CZK = 1,
    ETH_CZK = 2,
    XRP_CZK = 3,
    DASH_CZK = 4,
    BCH_CZK = 5,
    CZK_CZK = 6,
    EUR_CZK = 7,
    USD_CZK = 8,
}

export declare type PairType =
    | 'BTC_CZK'
    | 'LTC_CZK'
    | 'ETH_CZK'
    | 'XRP_CZK'
    | 'DASH_CZK'
    | 'BCH_CZK'
    | 'CZK_CZK'
    | 'EUR_CZK'
    | 'USD_CZK';

// ["BTC_CZK", "LTC_CZK", "ETH_CZK", "XRP_CZK", "DASH_CZK", "BCH_CZK", "CZK_CZK", "EUR_CZK", "USD_CZK"]
export const getPairsArray = (): PairType[] =>
    Object.keys(PairEnum).filter((key) => isNaN(+key)) as PairType[];
export const getPairsAsNumbersArray = (): number[] =>
    Object.keys(PairEnum)
        .filter((key) => !isNaN(+key))
        .map((pairNumber) => +pairNumber);
