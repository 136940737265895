import firebase from 'firebase/app';
import 'firebase/database';
import React from 'react';

import { DatabaseRecord, DefaultProps } from '../utils/interfaces';

export const withDatabase = (WrappedComponent: React.ComponentType<DefaultProps>) =>
    class WithDatabase extends React.Component<DefaultProps, DefaultProps> {
        componentDidMount() {
            if (!this.props.settings) {
                firebase
                    .database()
                    .ref('/settings/')
                    .once('value')
                    .then((snapshot: firebase.database.DataSnapshot) => {
                        this.props.updateSettings(snapshot.val());
                    });
            }
            if (!this.props.database) {
                firebase
                    .database()
                    .ref('/records/')
                    .on('value', (snapshot: firebase.database.DataSnapshot) => {
                        this.props.updateDatabase(
                            snapshot
                                .val()
                                .reverse()
                                .map((record: DatabaseRecord, index: number) => ({
                                    ...record,
                                    key: index,
                                })) as DatabaseRecord[]
                        );
                    });
            }
        }

        unsubscribeMethod: firebase.Unsubscribe = () => null;

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };
