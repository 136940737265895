import firebase from 'firebase/app';
import { Button, Col, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { actionTypes, getActionFunction } from '../actions';
import { updateBitfinexBalance } from '../utils/firebaseDb';
import { Settings, State } from '../utils/interfaces';

function BottomBar(
    props: {
        settings: Settings;
        updateSettings: (settings: Settings) => void;
    } = {} as {
        settings: Settings;
        updateSettings: (settings: Settings) => void;
    }
) {
    // const [firstLoad, setFirstLoad] = useState(false);
    const [loadingDollars, setLoadingDollars] = useState(false);
    const [dollars, setDollars] = useState(0);

    const saveBitfinexBalance = () => {
        const newSettings: Settings = {
            ...props.settings,
            BITFINEX_BALANCE: dollars,
        };
        props.updateSettings(newSettings);
        updateBitfinexBalance(dollars, () => setLoadingDollars(false));
    };

    useEffect(() => {
        if (props.settings?.BITFINEX_BALANCE) {
            setDollars(props.settings.BITFINEX_BALANCE);
            // setFirstLoad(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.settings]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const reg = /^\d*$/;
        if (!isNaN(+value) && reg.test(value)) {
            setDollars(+value);
        }
    };

    return (
        <Row justify="center">
            <Col xs={24} md={8}>
                <Row justify="center" style={{ padding: '0 12px 12px 12px' }}>
                    <Input
                        size="large"
                        value={dollars}
                        onChange={onChange}
                        style={{ width: '33%', marginRight: '12px' }}
                        disabled={dollars === 0 || loadingDollars}
                    />
                    <Button
                        type="primary"
                        size={'large'}
                        onClick={() => {
                            setLoadingDollars(true);
                            saveBitfinexBalance();
                        }}
                        loading={loadingDollars}
                    >
                        Update $
                    </Button>
                </Row>
            </Col>
            <Col xs={24} md={8}>
                <Row justify="center" style={{ padding: '0 12px 12px 12px' }}>
                    <Button
                        size={'large'}
                        onClick={() => {
                            Object.keys(window.localStorage)
                                .filter((item) => item.startsWith('firebase'))
                                .forEach((key) => {
                                    window.localStorage.removeItem(key);
                                });
                            firebase.auth().signOut();
                        }}
                        style={{ margin: '0 6px' }}
                    >
                        Log out
                    </Button>
                </Row>
            </Col>
        </Row>
    );
}

const mapStateToProps = (state: State): { settings: Settings } => ({
    settings: state.settings,
});

const mapDispatchToProps = (dispatch: any) => ({
    updateSettings: (settings: Settings) =>
        dispatch(getActionFunction(actionTypes.UPDATE_SETTINGS, settings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BottomBar);
