import { Card, Layout, Spin } from 'antd';
import firebase from 'firebase/app';
import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { LoadingOutlined } from '@ant-design/icons';

import { DefaultProps } from '../utils/interfaces';
import { errorMessage } from '../utils/message';

// const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
const antIcon = <LoadingOutlined />;
// Configure FirebaseUI.
const uiConfig = () => ({
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/',
    // We will display Google as auth providers.
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
});

export const withAuthentication = (
    WrappedComponent: React.ComponentType<DefaultProps>
) =>
    class WithAuthentication extends React.Component<
        DefaultProps,
        DefaultProps
    > {
        componentDidMount() {
            this.unsubscribeMethod = firebase
                .auth()
                .onAuthStateChanged((authUser) => {
                    if (authUser) {
                        //  authUser.email === 'znojjj@gmail.com' replaced with database.rules.json
                        this.props.updateAuthUser(authUser);
                    } else if (authUser) {
                        errorMessage('This page is not for you.');
                    }
                });
        }

        componentWillUnmount() {
            if (this.unsubscribeMethod) {
                this.unsubscribeMethod();
            }
        }

        unsubscribeMethod: firebase.Unsubscribe = () => null;

        render() {
            const { authUser } = this.props;
            const cashedLogin = Object.keys(
                window.localStorage
            ).filter((item) => item.startsWith('firebase'))[0];

            return authUser ? (
                <WrappedComponent {...this.props} />
            ) : (
                <Layout style={{ height: '100vh' }}>
                    <Card style={{ margin: 'auto' }}>
                        {cashedLogin ? (
                            <Spin indicator={antIcon} />
                        ) : (
                            <StyledFirebaseAuth
                                uiConfig={uiConfig()}
                                firebaseAuth={firebase.auth()}
                            />
                        )}
                    </Card>
                </Layout>
            );
        }
    };
