import React from 'react';

import { DatabaseRecord, MovementColumn } from './interfaces';
import { getPairsArray, PairEnum, PairType } from './pairs';

export enum MovementEnum {
    avb = 0,
    balance = 1,
    rate = 2,
    result = 3,
}
export declare type MovementType = 'avb' | 'balance' | 'rate' | 'result';

// ["avb", "balance", "rate", "result"]
export const getMovementsArray = (): MovementType[] =>
    Object.keys(MovementEnum).filter((key) => isNaN(+key)) as MovementType[];

const movements: MovementColumn[] = [];
const currenciesToColumns: MovementColumn[] = [];

export const getCurrenciesToColumns = (): any[] => {
    if (currenciesToColumns.length === 0) {
        movements.length = 0;
        getMovements();
    }
    return currenciesToColumns;
};

export const getMovements = (): MovementColumn[] => {
    if (movements.length === 0) {
        currenciesToColumns.length = 0;
        getPairsArray().forEach((currency: PairType, index: number) => {
            getMovementsArray().forEach((key: MovementType) => {
                key === 'avb'
                    ? movements.push({
                          title: currency,
                          key: `${currency}_${key}`,
                          render: (x: DatabaseRecord) => {
                              const value: number = [
                                  PairEnum.USD_CZK,
                                  PairEnum.EUR_CZK,
                                  PairEnum.CZK_CZK,
                              ].includes(index)
                                  ? Math.round(x.data[index][key])
                                  : x.data[index][key];
                              return {
                                  props: {
                                      className: `customCell${index}`, // there it is!
                                  },
                                  children: (
                                      <div>{value > 0 ? +value : '-'}</div>
                                  ),
                              };
                          },
                      })
                    : currenciesToColumns.push({
                          title: `${currency} ${key}`,
                          key: `${currency}_${key}`,
                          width: 100,
                          render: (x: DatabaseRecord) => {
                              const value: number = x.data[index][key];
                              return {
                                  props: {
                                      className: `customCell${index}`, // there it is!
                                  },
                                  children: value
                                      ? (value > 100
                                            ? Math.round(value)
                                            : value.toFixed(3)
                                        ).toLocaleString('cs-CZ')
                                      : value,
                              };
                          },
                      });
            });
        });
    }
    return movements;
};
