import { actionTypes } from './actions';
import { State } from './utils/interfaces';

const initialState: State = {} as State;

export const store = (state: State = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.UPDATE_AUTH_USER:
            return { ...state, authUser: action.data };
        case actionTypes.UPDATE_SETTINGS:
            return { ...state, settings: action.data };
        case actionTypes.UPDATE_DATABASE:
            return { ...state, database: action.data };
        default:
            return state;
    }
};
