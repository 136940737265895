import firebase from 'firebase/app';
import "firebase/database";

import { DatabaseRecord } from './interfaces';
import { errorMessage, successMessage } from './message';

const onComplete = (name: string, onDone: () => void, error: Error | null) => {
    if (error) {
        // The write failed...
        errorMessage(`${name} - error: ${error}`);
    } else {
        // Data saved successfully!
        successMessage(`${name} - success`);
    }
    onDone();
};

export const updateBitfinexBalance = (dollars: number, onDone: () => void) => {
    firebase
        .database()
        .ref('/settings/BITFINEX_BALANCE')
        .set(dollars, (error) =>
            onComplete('update BitFinex balance', onDone, error)
        );
};

export const addNewRecord = (
    database: DatabaseRecord[],
    newRecord: DatabaseRecord,
    onDone: () => void
) => {
    const todaysMidnight = new Date(new Date().toDateString()).getTime();
    if (
        database.filter(
            (record: DatabaseRecord) => record.date >= todaysMidnight
        ).length === 0
    ) {
        firebase
            .database()
            .ref('/records/')
            .once('value', (snapshot: firebase.database.DataSnapshot) => {
                const firebaseDb = snapshot.val();
                firebase
                    .database()
                    .ref(`/records/${firebaseDb.length}`)
                    .set({ ...newRecord, key: firebaseDb.length }, (error) =>
                        onComplete('Add new record', onDone, error)
                    );
            });
    } else {
        onDone();
    }
};
