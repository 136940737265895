import { message } from 'antd';

export const errorMessage = (text: string) => {
    message.error(text, 5);
};

export const successMessage = (text: string) => {
    message.success({
        content: text,
        style: { marginTop: 'Calc(100vh - 64px)', textAlign: 'end' },
        duration: 1,
    });
};
