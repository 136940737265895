import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

import MainRouter from './MainRouter';
import { store } from './reducer';
import * as serviceWorker from './serviceWorker';

const myStore = createStore(store);
// myStore.subscribe(() => console.log(myStore.getState()));

ReactDOM.render(
    <Provider store={myStore}>
        <MainRouter />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
