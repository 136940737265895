import { Button, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { tradeColumns } from '../utils/columns';
import { trade } from '../utils/fetch';
import { DatabaseRecord, Settings, State, TradeRow } from '../utils/interfaces';
import LastUpdatedString from './LastUpdatedString';

function LastTrades(
    props: { settings: Settings } = {} as {
        database: DatabaseRecord[];
        settings: Settings;
    }
) {
    const [firstLoad, setFirstLoad] = useState(false);
    const [lastTrade, setLastTrade] = useState([] as TradeRow[]);
    const [loading, setLoading] = useState(false);
    const [lastUpdate, setLastUpdate] = useState(
        new Date().toLocaleString('cs-CZ')
    );

    const addLastTrade = (lastTradeParam: TradeRow[]) => {
        const lastTradeParamWithKeys = lastTradeParam.map(
            (tradeRow: TradeRow) => ({
                ...tradeRow,
                key: tradeRow.orderId,
            })
        );
        if (lastTradeParamWithKeys.length > 0) {
            setLastTrade(lastTradeParamWithKeys);
            setLastUpdate(new Date().toLocaleString('cs-CZ'));
        }
        setLoading(false);
    };

    const fetch = () => {
        setLoading(true);
        trade(props.settings, addLastTrade);
    };

    useEffect(() => {
        if (!firstLoad && props.settings) {
            setFirstLoad(true);
            fetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.settings]);

    return (
        <>
            <Row justify="end">
                <div>
                    <Button
                        type="primary"
                        size={'large'}
                        onClick={() => {
                            fetch();
                        }}
                        loading={loading}
                    >
                        Last Trades
                    </Button>
                </div>
            </Row>
            <LastUpdatedString name={'Last Trades'} lastUpdate={lastUpdate} />
            <Table
                rowKey="transactionId"
                key="lastTrade"
                loading={lastTrade.length === 0}
                dataSource={lastTrade}
                columns={tradeColumns}
                pagination={{ size: 'small', pageSize: 6 }}
            />
        </>
    );
}

const mapStateToProps = (
    state: State
): { database: DatabaseRecord[]; settings: Settings } => ({
    database: state.database,
    settings: state.settings,
});

export default connect(mapStateToProps, null)(LastTrades);
