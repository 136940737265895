import { Table } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import { columns } from '../utils/columns';
import { DatabaseRecord, State } from '../utils/interfaces';

function ShortedTable(
    props: { database: DatabaseRecord[] } = {} as { database: DatabaseRecord[] }
) {
    return (
        <Table
            rowKey="key"
            key="simple"
            loading={!props.database}
            dataSource={props.database}
            columns={columns
                .filter(
                    (column) => column.key !== 'dif' && column.key !== 'hodl'
                )
                .map((column) => ({ ...column, width: '25%' }))}
            pagination={{ size: 'small', pageSize: 6 }}
        />
    );
}

const mapStateToProps = (state: State): { database: DatabaseRecord[] } => ({
    database: state.database,
});

export default connect(mapStateToProps, null)(ShortedTable);
