import { Layout } from 'antd';
import React, { useState } from 'react';

import BottomBar from './Components/BottomBar';
import FullTable from './Components/FullTable';
import LastTrades from './Components/LastTrades';
import Movement from './Components/Movement';
import ShortedTable from './Components/ShortedTable';
import TopBar from './Components/TopBar';
import { withSettings } from './HOC/withSettings';

const { Content, Footer } = Layout;

function Home() {
    const [width] = useState(window.innerWidth);

    const isMobile = width <= 500;

    return (
        <>
            <Layout>
                <TopBar />
                <Content style={{ margin: '0 12px 0' }}>
                    <div
                        style={{
                            padding: '12px',
                            minHeight: 360,
                        }}
                    >
                        <Movement />
                        <ShortedTable />
                        <LastTrades />
                        {!isMobile ? <FullTable /> : ''}
                        <BottomBar />
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>znoj.cz</Footer>
            </Layout>
        </>
    );
}

export default withSettings(Home);
