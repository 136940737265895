import { Table } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import { fullTableColumns } from '../utils/columns';
import { DatabaseRecord, State } from '../utils/interfaces';
import { getMovementsArray } from '../utils/movements';
import { getPairsArray } from '../utils/pairs';
import LastUpdatedString from './LastUpdatedString';

function FullTable(props: { database: DatabaseRecord[] } = {} as { database: DatabaseRecord[] }) {
    return (
        <>
            <LastUpdatedString name={'Full table'} />
            <Table
                key="full"
                loading={!props.database}
                dataSource={props.database}
                columns={fullTableColumns}
                pagination={{ size: 'small', pageSize: 5 }}
                scroll={{
                    x: getPairsArray().length + getMovementsArray().length * 3 * 70, // magic
                }}
            />
        </>
    );
}

const mapStateToProps = (state: State): { database: DatabaseRecord[] } => ({
    database: state.database,
});

export default connect(mapStateToProps, null)(FullTable);
