import cryptoJs from 'crypto-js';
import { Settings } from './interfaces';
import { errorMessage } from './message';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const crypto = require('crypto');

// TradeRow[] | CoinMateBalance
export const post = (
    url: string,
    settings: Settings,
    onDone: (data: any) => void
) => {
    const nonce = Date.now().toString();
    const clientId = settings.CLIENT_ID;
    const publicKeyCM = settings.PUBLIC_KEY_CM;
    const privateKeyCM = settings.PRIVATE_KEY_CM;
    const signatureMessage = nonce + clientId.toString() + publicKeyCM;

    const signature = crypto
        .createHmac('sha256', privateKeyCM)
        .update(signatureMessage)
        .digest('hex')
        .toUpperCase();

    const request: XMLHttpRequest = new XMLHttpRequest();

    request.open('POST', url);
    request.setRequestHeader(
        'Content-Type',
        'application/x-www-form-urlencoded'
    );
    request.onreadystatechange = function () {
        if (this.readyState === 4) {
            // console.log('Status:', this.status);
            // console.log('Headers:', this.getAllResponseHeaders());
            // console.log('Body:', this.responseText);
            // console.log('------------\n');
            let data = { data: [], error: null, errorMessage: '' };
            try {
                data = JSON.parse(this.responseText);
            } catch (e) {
                errorMessage(
                    `title: ${url}
                    status: ${this.status}
                    more: ${this.responseText}`
                );
            }

            data.error &&
                errorMessage(
                    `title: ${url}
                    status: ${this.status}
                    more: ${
                        data.errorMessage ? (data.errorMessage as string) : null
                    }`
                );
            onDone(data.data === null ? [] : data.data);
        }
    };
    request.send(`clientId=${clientId}&nonce=${nonce}&signature=${signature}`);
};

export const get = (url: string, onDone: (data: any[] | any) => void) => {
    const request: XMLHttpRequest = new XMLHttpRequest();
    request.open('GET', url);
    request.onreadystatechange = function () {
        if (this.readyState === 4) {
            // console.log('Status:', this.status);
            // console.log('Headers:', this.getAllResponseHeaders());
            // console.log('Body:', this.responseText);
            // console.log('------------\n');
            let data = { data: [], error: null, errorMessage: '' };
            try {
                data = JSON.parse(this.responseText);
            } catch (e) {
                errorMessage(
                    `title: ${url}
                    status: ${this.status}
                    more: ${this.responseText}`
                );
            }

            data.error &&
                errorMessage(
                    `title: ${url}
                    status: ${this.status}
                    more: ${
                        data.errorMessage ? (data.errorMessage as string) : null
                    }`
                );
            if (url.indexOf('coinmate') < 0) {
                onDone(data);
                return;
            }
            onDone(data.data === null ? [] : data.data);
        }
    };
    request.send();
};

export const postBitfinex = (
    settings: Settings,
    onDone: (data: any) => void
) => {
    const apiKey = settings.PUBLIC_KEY_BFX;
    const apiSecret = settings.PRIVATE_KEY_BFX;
    const authNonce = Date.now() * 1000; // Generate an ever increasing, single use value. (a timestamp satisfies this criteria)
    const authPayload = `AUTH${authNonce}`; // Compile the authentication payload, this is simply the string 'AUTH' prepended to the nonce value
    const authSig = cryptoJs
        .HmacSHA384(authPayload, apiSecret)
        .toString(cryptoJs.enc.Hex); // The authentication payload is hashed using the private key, the resulting hash is output as a hexadecimal string

    const payload = {
        apiKey, // API key
        authSig, // Authentication Sig
        authNonce,
        authPayload,
        event: 'auth', // The connection event, will always equal 'auth'
    };
    const wss = new WebSocket('wss://api.bitfinex.com/ws/2'); // Create new Websocket
    wss.onopen = () => wss.send(JSON.stringify(payload));
    wss.onmessage = (event) => {
        // The 'message' event is called whenever the ws recieves ANY message
        const response = JSON.parse(event.data);
        if (response.length > 0 && response[1] === 'bu') {
            onDone(response[2][0]);
            wss.close();
        }
    };
};
