import firebase from 'firebase/app';
import React from 'react';
import { connect } from 'react-redux';

import { actionTypes, getActionFunction } from '../actions';
import {
    DatabaseRecord,
    DefaultProps,
    Settings,
    State,
} from '../utils/interfaces';
import { withAuthentication } from './withAuthentication';
import { withDatabase } from './withDatabase';

export const withSettings = (
    WrappedComponent: React.ComponentType<DefaultProps>
) => {
    const mapStateToProps = (state: State) => ({
        authUser: state.authUser,
        settings: state.settings,
        database: state.database,
    });

    const mapDispatchToProps = (dispatch: any) => ({
        updateAuthUser: (authUser: firebase.User | null) =>
            dispatch(getActionFunction(actionTypes.UPDATE_AUTH_USER, authUser)),
        updateSettings: (settings: Settings) =>
            dispatch(getActionFunction(actionTypes.UPDATE_SETTINGS, settings)),
        updateDatabase: (database: DatabaseRecord[]) =>
            dispatch(getActionFunction(actionTypes.UPDATE_DATABASE, database)),
    });

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        withAuthentication(
            withDatabase(
                class WithSettings extends React.Component<
                    DefaultProps,
                    DefaultProps
                > {
                    render() {
                        return <WrappedComponent {...this.props} />;
                    }
                }
            )
        )
    );
};
