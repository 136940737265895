import { Button, Row, Col } from 'antd';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { actionTypes, getActionFunction } from '../actions';
import { fetch } from '../utils/fetch';
import { addNewRecord, updateBitfinexBalance } from '../utils/firebaseDb';
import { DatabaseRecord, Settings, State } from '../utils/interfaces';
import { PairEnum } from '../utils/pairs';

function TopBar(
    props: {
        settings: Settings;
        database: DatabaseRecord[];
        updateDatabase: (database: DatabaseRecord[]) => void;
        updateSettings: (settings: Settings) => void;
    } = {} as {
        settings: Settings;
        database: DatabaseRecord[];
        updateDatabase: (database: DatabaseRecord[]) => void;
        updateSettings: (settings: Settings) => void;
    }
) {
    const [loading, setLoading] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    const [usdToCzk, setUsdToCzk] = useState(0);

    const saveNewData = (newRecord: DatabaseRecord) => {
        if (newRecord.data) {
            newRecord.key = props.database.length;
            props.updateDatabase([newRecord, ...props.database]);
            props.updateSettings({
                ...props.settings,
                BITFINEX_BALANCE: newRecord.data[PairEnum.USD_CZK].balance,
            });
            setUsdToCzk(newRecord.data[PairEnum.USD_CZK].result);
            updateBitfinexBalance(
                newRecord.data[PairEnum.USD_CZK].balance,
                () => {
                    /**/
                }
            );
            addNewRecord(props.database, newRecord, () => setLoading(false));
        }
    };

    useEffect(() => {
        if (!firstLoad && props.settings && props.database) {
            setLoading(true);
            setFirstLoad(true);
            fetch(props.settings, saveNewData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.settings, props.database]);

    return (
        <Row
            justify="space-between"
            align="middle"
            style={{ padding: '24px 24px 0 24px' }}
        >
            <Row align="middle">
                <Col>
                    <h2 style={{ margin: 0 }}>
                        {props?.settings?.BITFINEX_BALANCE.toLocaleString()}
                    </h2>
                </Col>
                <Col style={{ padding: '0 8px' }}>
                    {usdToCzk > 0 ? ` (${usdToCzk.toLocaleString()})` : ''}
                </Col>
            </Row>
            <Button
                type="primary"
                size={'large'}
                onClick={() => {
                    setLoading(true);
                    fetch(props.settings, saveNewData);
                }}
                loading={loading}
                style={{ margin: '0 6px' }}
            >
                Update
            </Button>
        </Row>
    );
}

const mapStateToProps = (
    state: State
): { settings: Settings; database: DatabaseRecord[] } => ({
    settings: state.settings,
    database: state.database,
});

const mapDispatchToProps = (dispatch: any) => ({
    updateDatabase: (database: DatabaseRecord[]) =>
        dispatch(getActionFunction(actionTypes.UPDATE_DATABASE, database)),
    updateSettings: (settings: Settings) =>
        dispatch(getActionFunction(actionTypes.UPDATE_SETTINGS, settings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
