/* eslint-disable react/display-name */
import { Tooltip } from 'antd';
import React from 'react';

import { TradeRow } from './interfaces';
import { getCurrenciesToColumns } from './movements';

export const columns = [
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (value: number) => new Date(value).toLocaleDateString('cs-CZ'),
    },
    {
        title: 'Time',
        dataIndex: 'date',
        key: 'time',
        render: (value: number) => new Date(value).toLocaleTimeString('cs-CZ'),
    },
    {
        title: 'Full balance',
        dataIndex: 'fullBalance',
        key: 'fullBalance',
        render: (value: string) => value,
    },
    {
        title: 'Profit',
        dataIndex: 'profit',
        key: 'profit',
        render: (value: number) => (
            <div style={{ color: value > 0 ? '#00c853' : '#d50000' }}>
                <b>{value.toLocaleString('cs-CZ')} </b>
            </div>
        ),
    },
    {
        title: 'Hodl',
        dataIndex: 'hodl',
        key: 'hodl',
        render: (value: number) => value.toLocaleString('cs-CZ'),
    },
    {
        title: 'Diff',
        dataIndex: 'dif',
        key: 'dif',
        render: (value: number) => <b>{value.toLocaleString('cs-CZ')} </b>,
    },
];

export const fullTableColumns = [
    ...columns
        .filter((column) => column.key === 'date')
        .map((column) => ({
            ...column,
            fixed: 'left',
            width: 110,
        })),
    ...getCurrenciesToColumns(),
    ...columns
        .filter((column) => column.key !== 'date' && column.key !== 'time')
        .map((column) => ({
            ...column,
            key: `full ${column.key}`,
            fixed: 'right',
            width: 100,
        })),
];

export const tradeColumns = [
    {
        title: 'Pair',
        dataIndex: 'currencyPair',
        key: 'currencyPair',
        render: (value: string, record: TradeRow) => (
            <div style={{ color: record.type === 'BUY' ? '#00c853' : '#d50000' }}>
                <b>{value.replace('_', '')}</b>
            </div>
        ),
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (value: number) => Number(value.toFixed(3)).toLocaleString('cs-CZ'),
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render: (value: number) => (value > 100 ? Math.round(value) : +value.toFixed(3)).toLocaleString('cs-CZ'),
    },
    {
        title: 'Time',
        dataIndex: 'createdTimestamp',
        key: 'createdTimestamp',
        render: (value: number) => {
            const dateObject: Date = new Date(value);
            return (
                <Tooltip title={dateObject.toLocaleDateString('cs-CZ')}>
                    <span>{dateObject.toLocaleTimeString('cs-CZ')}</span>
                </Tooltip>
            );
        },
    },
];
